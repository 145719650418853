import { createContext } from 'react'

import { TourProps } from './tour.types'

export const TourContext = createContext<TourProps>({
  userTourRef1: { current: null },
  userTourRef2: { current: null },
  userTourRef3: { current: null },
  userTourRef4: { current: null },
  backofficeTourRef1: { current: null },
  // backofficeTourRef2: { current: null },
  backofficeTourRef3: { current: null },
  backofficeTourRef4: { current: null },
  backofficeTourRef5: { current: null },
  tourSteps: [],
  openTour: undefined,
  startTour: () => undefined,
  tourFinished: {
    userTour: false,
    userBackofficeTour: false,
  },
})
