import { UploadFile } from 'antd'

export const percentageText = (value: number) => {
  return `${Math.round(value * 100)}%`
}

export const convertToFileList = (fileList: UploadFile<any>[]) => {
  const files = fileList.map((file) => file.originFileObj)
  const convertedFileList = new DataTransfer()
  files.forEach((file) => convertedFileList.items.add(file as File))
  return convertedFileList.files
}
