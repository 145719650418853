import { createContext } from 'react'

import { DrawerProps } from '@/context/conversationDrawer/drawer.types'

export const DrawerContext = createContext<DrawerProps>({
  close(): void {},
  show(): void {},
  open: false,
  selectedConversation: undefined,
  setSelectedConversation(): void {},
  mutateConversations(): void {},
  conversations: [],
  setSize(): void {},
  noMoreData: false,
})
