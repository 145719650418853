import { useTheme } from 'next-themes'

import { Theme } from '@/types/theme'

const useThemeState = () => {
  const { resolvedTheme } = useTheme()

  return { theme: (resolvedTheme as Theme) ?? Theme.DARK }
}

export default useThemeState
